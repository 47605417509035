@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(139, 62, 158),rgb(73, 23, 112));
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(rgb(110, 42, 211),rgb(194, 192, 195));
}


html,body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}






#root {
  height: 100%;
  width: 100%;
  background: #100C1B;
  overflow-x: hidden;
}

/* Background */



/* .inputSearch {
  @apply   bg-transparent flex bg-opacity-10 px-3 py-1.5
          text-base
          font-normal
          text-gray-700
          bg-clip-padding
          border border-solid border-gray-300
          rounded-2xl
          transition
          ease-in-out
          placeholder-teal-900
          focus:text-gray-700 
          focus:bg-transparent
           focus:border-gray-300 
           focus:outline-none
} */

.active{
  background:#2C2445;
  color: #D8D3E3;
}


.tv-lightweight-charts{
  border-radius: 20px;
}

.h-charts{
  height: 344px;
}

/* Spinner */

.spinner-box {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 0;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}


.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f16fc096;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}




