

.swapbutton {
  position: absolute;
  height: 450px;
  border-radius: 150px;
  filter: blur(50px);
}


.swapbutton span {
  position: static;
  min-width: 450px;
  height: 450px;
  border-radius: 50%;
  background: linear-gradient(#F7C64E, #BD64B0, #7449F6);
  filter: blur(20px);
  animation: animate 17s linear infinite;
}



@keyframes animate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* @media (max-width: 580px){
  .swapbutton span {
    position: absolute;
    display: block;
    width: 550px;
    height: 550px;
  }

  .swapbutton {
    position: relative;
  }
} */